<script setup>
const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
})
const { t, locale } = useI18n()
const localePath = useLocalePath()

// Helper function to get the current locale from a collection of translations
function getCurrentLocale(translations, fallback = true) {
  return (
    translations.find((t) => t.languages_code === locale.value) ||
    (fallback ? translations[0] : undefined)
  )
}

// Fetch the data and convert it to a format that the BlockSlider or BlockLinks component can render
// has to be done with useAsyncData because of possible randomization of items
const { data: convertedData } = await useAsyncData(
  `BlockList-${props.data.id}`,
  async function () {
    let items = props.data.items || []
    if (props.data.mode === 'collection' && props.data.collection) {
      if (props.data.collection === 'news') {
        const newsStore = useNewsStore()
        await callOnce('news-init', newsStore.init)
        items = newsStore.news
      } else if (props.data.collection === 'institutions') {
        const institutionStore = useInstitutionStore()
        await callOnce('institution-init', institutionStore.initInstitutions)
        items = institutionStore.payload
      } else if (props.data.collection === 'demo_searches') {
        const searchStore = useSearchStore()
        await callOnce('demo-searches-init', searchStore.initDemoSearches)
        items = searchStore._demoSearches
      } else if (props.data.collection === 'content') {
        // TODO: yet to be implemented, fetch items from content
      }

      // Restructure the items to have the same format for both types
      items = items.map((item) => {
        return {
          collection: props.data.collection,
          item: item,
        }
      })
    }
    if (props.data.type === 'links' && items.length > 0) {
      return convertToToBlockLinks(items)
    } else if (props.data.type === 'slider' && items.length > 0) {
      return convertToToBlockSlider(items)
    }
  }
)

// Helper function to pick a number of items from an array and randomize the order
// fallbock if numItems is 0 to original array
Array.prototype.pickItemsAndRandomize = function (numItems, randomize = false) {
  let result = this
  if (randomize) {
    result = result.sort(() => Math.random() - 0.5)
  }
  if (numItems > 0) {
    result = result.slice(0, numItems)
  }
  return result
}

// The incoming data is a collection of items, each with a collection key
// depending on the collection key, we need to convert the item to a different format
// so that the BlockSliderItem component can render it
function convertToToBlockSlider(items) {
  return {
    type: 'standard',
    aspect_ratio: props.data.aspect_ratio || '3/1',
    row_mode: props.data.row_mode,
    items: items
      .flatMap((item) => {
        const localized = getCurrentLocale(item.item.translations)
        // possible collections: institutions, content, demo_searches
        if (item.collection === 'institutions') {
          return [
            {
              type: 'image-text',
              id: localized.slug,
              color_text: props.data.color_text,
              color_background: props.data.color_background,
              heading: localized.title,
              collection: item.collection,
              summary: localized.summary,
              image: item.item.hero_image,
              link: localePath('institutions') + '/' + localized.slug,
            },
          ]
        } else if (item.collection === 'content') {
          const localized = getCurrentLocale(item.item.translations)
          return [
            {
              type: 'text',
              heading: localized.title,
              collection: item.collection,
              color_text: props.data.color_text,
              color_background: props.data.color_background,
              link: localePath('content') + '/' + localized.slug,
            },
          ]
        } else if (item.collection === 'demo_searches') {
          const localized = getCurrentLocale(item.item.translations)
          return [
            {
              type: 'image-text',
              id: localized.slug,
              category: t('block.demo_searches'),
              heading: localized.name,
              summary: localized.summary,
              image: item.item.image,
              color_text: item.item.color_text || props.data.color_text,
              color_background:
                item.item.color_background || props.data.color_background,
              link: localePath('search') + '/' + item.item.url,
            },
          ]
        } else if (item.collection === 'news') {
          // TODO: yet to be implemented, transform items from news
        }
        return []
      })
      .pickItemsAndRandomize(props.data.pick_items, props.data.randomize),
  }
}

// The incoming data is a collection of items, each with a collection key
// depending on the collection key, we need to convert the item to a different format
// so that the BlockLinks component can render it
function convertToToBlockLinks(items) {
  return {
    type: 'standard',
    heading: props.data.heading,
    summary: props.data.summary,
    items: items
      .flatMap((item) => {
        const localized = getCurrentLocale(item.item.translations)
        if (item.collection === 'institutions') {
          return [
            {
              heading: localized.title,
              summary: localized.summary,
              link: localePath('institutions') + '/' + localized.slug,
            },
          ]
        } else if (item.collection === 'content') {
          const localized = getCurrentLocale(item.item.translations)
          return [
            {
              heading: localized.title,
              summary: localized.summary,
              link: localePath('content') + '/' + localized.slug,
            },
          ]
        } else if (item.collection === 'demo_searches') {
          const localized = getCurrentLocale(item.item.translations)
          return [
            {
              heading: localized.name,
              summary: localized.summary,
              link: localePath('search') + '/' + item.item.url,
            },
          ]
        } else if (item.collection === 'news') {
          const localized = getCurrentLocale(item.item.translations, false)
          if (localized !== undefined) {
            return [
              {
                heading: localized.heading,
                summary: localized.summary,
                call_to_action: localized.call_to_action,
                link: item.item.link,
              },
            ]
          }
        }
        return []
      })
      .pickItemsAndRandomize(props.data.pick_items),
  }
}
</script>
<template>
  <section
    v-if="convertedData"
    :class="['block-list', 'block-list-' + data.type]"
  >
    <BlockSlider v-if="data.type === 'slider'" :data="convertedData" />
    <BlockLinks v-if="data.type === 'links'" :data="convertedData" />
  </section>
</template>
